import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import axios from "axios";
import { Helmet } from "react-helmet";
/* Components */
import Footer from "../Footer";
/* Style */
import "../../styles/contact.css";
import "../../styles/accueil.css";
import "../../App.css";
/* Médias */
import arrowRight from "../../assets/arrow-right.svg";

function Contact() {
  const send = "Envoyer";
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [responseError, setresponseError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (fName === "" || lName === "" || message === "" || email === "") {
      setresponseError("Veuillez remplir tous les champs du formulaire");
    } else {
      axios
        .post("https://sahar.fr/api/email/", {
          firstname: fName,
          lastname: lName,
          message: message,
          from: email,
        })
        .then((response) => {
          setfName("");
          setEmail("");
          setlName("");
          setMessage("");
          setSuccess(true);
          setresponseError("");
        })
        .catch((error) => {
          setresponseError(
            "Veuillez réessayer ultérieurement ou contactez-nous sur contact@sahar.fr"
          );
        });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="SAHAR" />
      </Helmet>

      <Container className="container contact">

        {/* TITRE */}
        <Row>
          <Col lg={12}>
            <h2 style={{ textAlign: "center" }}>Contact</h2>
          </Col>
        </Row>

         {/* FORMULAIRE */}

        <form onSubmit={handleSubmit}>

          <Row>
          <Col lg={6}>
             
              
                <div className="first_name">
                  <input
                    id="name"
                    type="text"
                    value={lName}
                    onChange={(e) => setlName(e.target.value)}
                    placeholder="Votre nom"
                  />
                </div>
                </Col>
                <Col lg={6}>
                <div className="last_name">
                  <input
                    id="name"
                    value={fName}
                    onChange={(e) => setfName(e.target.value)}
                    type="text"
                    placeholder="Votre prénom"
                  />
                </div>
                
              
              </Col>
            </Row>
            <Row>

            <Col lg={12}>
              <div className="mail_message">
                <div></div>
                <input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Votre adresse email"
                />
                <div></div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id="message"
                  placeholder="Votre message"
                  taille="2,40"
                />
              </div>

              {success === true && (
                <div className="response_success">
                  <p>Votre message a bien été envoyé</p>
                </div>
              )}

              {responseError.length > 0 && success === false && (
                <div className="response_error">
                  <p>{responseError}</p>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <button type="submit" className="button">
                <img className="arrow" src={arrowRight} alt="Bouton envoyer" />
                {send}
              </button>
            </Col>{" "}
          </Row>
        </form>
      </Container>
      <Footer />
    </div>
  );
}

export default Contact;
