import React from "react";
import { Link } from "react-router-dom";
/* Style */
import "../styles/index.css";
import "../styles/about.css";
import { Container, Row, Col } from "react-grid-system";

function Footer() {
  const copyright = "© SAHAR 2024";
  const mentionsLegales = "Mentions Légales";
  const politiqueConfidentialite = "Politique de confidentialité";
  return (
    <Container className="footer container">
    <Row>

    <Col lg={5}>
  <div className="footer_contain line-footer"></div>
</Col>
  
      <Col lg={2}>
      <div className="footer_contain">
        <span>{copyright}</span>
      </div>
      </Col>
      <Col lg={2}>
      <div className="footer_contain">
        <span>
          <Link to="/mentionslegales">{mentionsLegales}</Link>
        </span>
      </div>
      </Col>

      {/* 
      <Col lg={2}>
      <div className="footer_contain">
        <span> {madeBy}</span>
      </div>
      </Col>
      */}
      <Col lg={3}>
      <div className="footer_contain">
        <span> <Link to="/confidentialite">{politiqueConfidentialite}</Link></span>
      </div>
      </Col>

      
      </Row>
    </Container>
  );
}

export default Footer;


/* import React from "react";
import { Link } from "react-router-dom";*/ 
/* Style */
/*
import "../styles/index.css";
import "../styles/about.css";

function Footer() {
  const copyright = "© SAHAR 2021";
  const mentionsLegales = "Mentions Légales";
  const madeBy = "Made by Sahar";
  return (
    <div className="footer">
      <div className="footer_contain line" style={{ width: 420 }}></div>
      <div className="footer_contain">
        <span>{copyright}</span>
      </div>
      <div className="footer_contain">
        <span>
          <Link to="/mentionslegales">{mentionsLegales}</Link>
        </span>
      </div>
      <div className="footer_contain">
        <span> {madeBy}</span>
      </div>
    </div>
  );
}

export default Footer; */
