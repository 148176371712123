import React, { useEffect }  from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-grid-system";

import "../../styles/accueil.css";
import "../../styles/footer.css";

import Footer from '../Footer';

function Confidentialite() {

  useEffect(() => {
    window.scrollTo(0,0)
}, []);

  const title = "Politique de confidentialité et de respect des données privées"
  const maj = "Dernière mise à jour : 03/10/2023"

  return (
    <div>
      <Helmet>
        <title>Sahar | Analyse de données massives et solutions cyber</title>
        <meta
          name="description"
          content="SAHAR est spécialisée dans la collecte, l’analyse et la visualisation de données massives. La société conçoit des outils pouvant s’insérer dans des chaînes de traitement ou fonctionner de façon autonome. Elle fonde ainsi sa proposition de valeur sur une approche flexible et innovante de la data science avec des travaux de recherche en IA, ML, Deep Learning, NLP ou encore étude des graphes."
        />
      </Helmet>

    <Container className="container">

<Row>
  <Col lg={12} style={{textAlign:'center', marginBottom:'8%', padding:'0'}}>
    <h2 style={{textAlign:'center', margin:'0', padding:'0'}}>{title}</h2>
    <span>{maj}</span>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <p>En utilisant le site Internet de la société SAHAR <a style={{color:'#4F71EC'}} href="https://sahar.fr">https://sahar.fr</a> (ci-après le « Site »), l’utilisateur (l’« Utilisateur ») accepte la collecte et l’utilisation de ses données à caractère personnel (les « Données Personnelles » ou « Données ») de la manière décrite dans la présente Politique de Confidentialité et ce, par SAHAR (la « Société »).</p>
    <p>Les traitements de Données Personnelles collectées à partir du Site sont effectués dans le respect de la loi du 6 janvier 1978 modifiée (« Loi Informatique et Libertés) ainsi que du Règlement Général sur la Protection des Données n° 679/2016 (« RGPD »). </p>
    <p>Le responsable de traitement des Données est la Société.</p>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Données collectées par la Société</h4>
    <p>Les Données collectées par la Société lors de l’utilisation du Site par l’Utilisateur sont strictement nécessaires aux finalités recherchées par la Société et décrites ci-dessous.</p>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Finalités de la collecte et du traitement de Données par la Société</h4>
    <p>Des Données peuvent être collectées lorsque l’Utilisateur :</p>
    <ul>
      <li>Navigue sur le Site ;</li>
      <li>S’inscrit à des lettres d’information de la Société ; </li>
      <li>Dépose une candidature à une offre d’emploi, de stage, de collaboration ; </li>
      <li>Demande une interview.</li>
    </ul>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Conservation de vos Données Personnelles</h4>
    <p>Les Données susvisées sont conservées uniquement pour la durée nécessaire aux finalités pour lesquelles elles sont collectées et traitées et ce, dans le respect de la législation en vigueur. </p>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Destinataires des Données</h4>
    <p>Les Données collectées et traitées par la Société au moyen du Site ne sont pas cédées ou louées, à titre gratuit ou onéreux, à des tiers. </p>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Sécurité</h4>
    <p>La Société s’engage à protéger la sécurité, la confidentialité et l’intégrité des Données des Utilisateurs fournies sur le Site par l’utilisation de moyens de sécurisation physiques et logiques.
  <br></br>Toutefois, et compte tenu des caractéristiques inhérentes à Internet comme moyen de communication global, la Société ne peut pas garantir que ces informations, lors d’une transmission sur Internet, ne puissent pas être interceptées par des tiers.</p>
  </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Cookies</h4>
    <p>En naviguant sur le Site, les Utilisateurs acceptent l’utilisation de cookies dans les conditions décrites ci-après. Si les Utilisateurs qui ne souhaitent pas accepter ces cookies peuvent les désactiver en suivant les instructions décrites ci-après. </p>
    <span style={{textDecoration:'underline'}}>Définition</span>
    <p className="textes-mineurs">Les cookies sont des données stockées dans l’équipement terminal d’un internaute et utilisées par le site que les internautes visitent pour envoyer des informations au navigateur, et permettant à ce navigateur de renvoyer des informations au site d’origine. Ils contiennent généralement le nom du serveur qui l’a déposé, un identifiant sous forme de numéro unique et une date d’expiration. Les cookies permettent de conserver, pendant la durée de validité du cookie, des informations d’état lorsqu’un navigateur accède aux différentes pages d’un site web ou lorsque ce navigateur retourne plus tard sur ce site Internet.</p>
    <span style={{textDecoration:'underline'}}>Finalité</span>
    <p className="textes-mineurs">Afin de proposer des services adaptés au terminal de l’Utilisateur (ordinateur, mobile ou tablette), les cookies utilisés par la Société ont pour finalités :</p>
    <ul>
      <li className="textes-mineurs">La collecte d’informations relatives à la navigation des Utilisateurs ;</li>
      <li className="textes-mineurs">L’analyse de la fréquentation et de l’utilisation du Site ; </li>
    </ul>
    <span style={{textDecoration:'underline'}}>Utilisation des cookies par la Société </span>
    <p className="textes-mineurs">Les cookies utilisés sur le Site sont les suivants : </p>
    <p className="textes-mineurs">Des <i>cookies de session</i> qui enregistrent des informations techniques lors de la navigation d’une page à une autre sur le Site et qui disparaissent dès que l’Utilisateur quitte le Site.</p>
    <p className="textes-mineurs">Des <i>cookies de fonctionnalités</i> permettant notamment de reconnaitre l’Utilisateur lors d’une nouvelle visite sur le Site et adapter le Site en fonction. Il s’agit de cookies permanents qui demeurent sur le terminal de l’Utilisateur jusqu’à expiration de leur durée de vie ou leur suppression à l’aide des fonctionnalités du navigateur. </p>
    <p className="textes-mineurs">Des <i>cookies de mesure d’audience Matomo</i> : ces cookies sont utilisés pour collecter de l’information, de manière anonyme, sur les utilisateurs des sites web. Ces cookies permettent de comptabiliser les visiteurs et d’identifier la manière dont ils utilisent le Site. Matomo est une alternative open-source qui permet de conserver le contrôle des données privées en assurant la sécurité et la propriété des données à 100%. </p>
    <p className="textes-mineurs">Vous pouvez consulter la politique de confidentialité de Matomo via ce lien : <a href="https://fr.matomo.org/100-data-ownership/" className="textes-mineurs" style={{textDecoration:'underline'}}>https://fr.matomo.org/100-data-ownership/</a> </p>
    <span style={{textDecoration:'underline'}}>Consentement – suppression et gestion des cookies </span>
    <p className="textes-mineurs">Les Utilisateurs disposent de plusieurs options pour supprimer les cookies. <br></br>La plupart des navigateurs sont paramétrés par défaut. Néanmoins, les Utilisateurs peuvent toujours choisir de désactiver un ou plusieurs cookies, refuser leur dépôt ou retirer leur consentement. L’Utilisateur a le choix d’accepter ou de refuser les cookies au cas par cas ou de les refuser de manière générale et systématique. </p>
    <p className="textes-mineurs">Les Utilisateurs ont également la possibilité de gérer les cookies utilisés sur le Site en paramétrant les navigateurs de leurs différents terminaux (tablettes, smartphones, ordinateurs), comme détaillé ci-après.</p>
    <p className="textes-mineurs">La configuration de chaque navigateur est décrite dans son menu d’aide. Généralement, il s’agit de se reporter à l’onglet « Paramètres », puis au menu « Options » ou « Préférences ».</p>
    <p className="textes-mineurs">Les Utilisateurs sont informés qu’en fonction du paramétrage choisi, les conditions d’accès au Site et à ses fonctionnalités peuvent le cas échéant être modifiées, ce dont la Société ne saurait être responsable.</p>
    <p className="textes-mineurs">Lorsque les Utilisateurs refusent le dépôt d’un cookie en suivant les paramètres exposés ci-avant, un cookie est installé et conservé sur leur navigateur afin de les identifier comme personne ayant refusé l’installation de cookies. Dans l’hypothèse où les Utilisateurs effaceraient ce cookie de leur navigateur ou qu’ils se connecteraient à partir d’un navigateur Internet différent ou un autre ordinateur, ils devraient alors entreprendre la même procédure de refus de dépôt des cookies.</p>
  </Col>
</Row>


<Row >
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Droits de l'utilisateur</h4>
    <p>Chaque Utilisateur dispose d’un droit d’accès, de modification, de rectification et de suppression des Données le concernant. </p>
    <p>Chaque Utilisateur a également la possibilité de s’opposer, pour des motifs légitimes, à ce que ses Données fassent l’objet d’un traitement par la Société.</p>
    <p>Ces droits peuvent être exercés en envoyant un courrier à : SAHAR - 10 rue Fromentin, 75009 PARIS ou un email à contact@sahar.fr. </p>
    </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Modification de la Politique de Confidentialité</h4>
    <p>La Société se réserve le droit de modifier la présente Politique de Confidentialité à tout moment. </p>
      <p>En cas de modification ou de mise à jour, la Politique de Confidentialité révisée sera mise en ligne sur le Site avec mention de la dernière date de mise à jour. </p>
     <p>L’Utilisateur devra en conséquence vérifier régulièrement le Site pour rester informé de tout changement ou mise à jour de la Politique de Confidentialité de la Société.</p>
      </Col>
</Row>

<Row>
  <Col lg={12} style={{marginBottom:'8%'}}>
    <h4>Loi et Juridiction applicable </h4>
    <p>La présente Politique de Confidentialité est régie par la loi française et tout litige à son sujet relèvera de la compétence exclusive des tribunaux de Paris.</p>
  </Col>
</Row>

    </Container>


      <Footer/>
    </div>
  );
}

export default Confidentialite;


