import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
/* import { Link } from "react-router-dom";*/

import "../../styles/footer.css";
import iconFirst from "../../assets/iconFirst.svg";
import iconSecond from "../../assets/iconSecond.svg";
import iconThird from "../../assets/iconThird.svg";
import arrowRight from "../../assets/arrow-right.svg";

import Footer from '../Footer';

function FactoryExpertise() {

    const factoryDescription = 'Nous développons des outils qui visent à collecter, retraiter, enrichir et visualiser de la donnée issue du web ouvert.'
    const factoryDetails = "Destinés au secteur public et privé, les produits de la factory s’adaptent au besoin client sur demande. SAHAR conçoit des outils en son nom propre ou en partenariat avec des clients, avec pour coeur de savoir-faire la restitution de données sur une plateforme web."
    const subtitleFirst = 'Collecte de données'
    const detailsFirst = "Acquisition de données structurées et semi-structurées provenant de sources multiples exploitables qui s’intègrent à la chaine de traitement. Développement de robots de scraping et d'indexation avancée."
    const subtitleSecond = 'Analyse de données'
    const detailsSecond = 'Analyses statiques et dynamiques de bases de données massives. Traitement de données automatisé avec différentes techniques en IA de ML, Deep Learning, Complex Networks et Computer Vision. Construction, optimisation et intégration de moteurs de recherche.'
    const subtitleThird = 'Visualisation et web'
    const detailsThird = 'Création de sites web pour explorer et visualiser des bases de données complexes. Vue d’ensemble sur la donnée et exploration par granularité. Exploration visuelle par cluster de graphes et visualisation par construction dynamique en temps réel.'
    const linkMore = "Advisory"
  return (
<div>
    <Container className="container" id="activities">
        {/* HERO */}
        <Row>
            <div className="flex">
            <Col lg={7} md={12} xs={12}>
            <div><h3>Factory</h3></div>
            <div><h1 style={{marginTop:'3%'}}>Développement d'outils cyber</h1></div>
            </Col>
            <Col lg={5}>
            <div className="description activities-description">
                <p style={{textAlign:'left'}}>{factoryDescription}</p>
              </div>
            </Col>
            </div>
        </Row>

        {/* MOBILE */}
        <Row>
        <Col xs={12}>
            <div className="description activities-description-mobile">
                <p style={{textAlign:'left'}}>{factoryDescription}</p>
              </div>
              </Col>
        </Row>
        {/* FIN MOBILE */}
        <Row>
            <Col lg={12} md={12} xs={12}>
            <div className="activities-details">
                <div className=""><p>{factoryDetails}</p></div>
            </div>
            </Col>
        </Row>
    </Container>

    <Container className="container activities-explications">
        <Row>
            <Col lg={4}>
                <img src={iconFirst} alt="" width="50px"/>
                <div className="subtitle"><h4>{subtitleFirst}</h4></div>
                <div className="details"><p className="small-text">{detailsFirst}</p></div>
            </Col>
            <Col lg={4}>
            <img src={iconSecond} alt="" width="50px"/>
                <div className="subtitle"><h4>{subtitleSecond}</h4></div>
                <div className="details"><p className="small-text">{detailsSecond}</p></div>
            </Col>
            <Col lg={4}>
            <img src={iconThird} alt="" width="50px"/>
                <div className="subtitle"><h4>{subtitleThird}</h4></div>
                <div className="details"><p className="small-text">{detailsThird}</p></div>
            </Col>
        </Row>
   
    <div style={{marginTop:'10%'}} className="right_button">
          <Link to="/advisory">
            {linkMore}
            <img className="arrow_bottom" src={arrowRight} alt="" />
          </Link>
        </div>
        </Container>
    <Footer/>
</div>
    );
}

export default FactoryExpertise;