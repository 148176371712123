import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
/* Components */
import AdvisoryExpertise from "../../components/Advisory/AdvisoryExpertise";

function Advisory() {
  useEffect(() => {
    window.scrollTo(0,0)
}, []);

  return (
    <div>
      <Helmet>
        <title>SAHAR | Advisory</title>
        <meta name="description" content="SAHAR accompagne les acteurs privés et publics dans la conduite de leur projet data, et dans les développements stratégiques à haute complexité." />
      </Helmet>

          <AdvisoryExpertise/>
        
    </div>
  );
}

export default Advisory;
