import React from 'react';
import { render } from 'react-snapshot';
import './styles/index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Favicon from 'react-favicon';
import SaharFavicon from './assets/Logo-White.svg'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'


const instance = createInstance({
  urlBase: 'https://matomo.sahar.technology/',
  siteId: 5,
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST'
  }
})

render(
  <MatomoProvider value={instance}>

  <React.StrictMode>
    <Favicon url={SaharFavicon} />
    <App />
  </React.StrictMode>
  </MatomoProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
