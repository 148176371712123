import React from "react";
import { Helmet } from "react-helmet";
/* Components */
import Accueil from "../Homepage/Accueil";


/* Style 
import "../../App.css";*/
import "../../styles/index.css";

function HomePage() {
  return (
    <div>
      <Helmet>
        <title>Sahar | Analyse de données massives et solutions cyber</title>
        <meta
          name="description"
          content="SAHAR est spécialisée dans la collecte, l’analyse et la visualisation de données massives. La société conçoit des outils pouvant s’insérer dans des chaînes de traitement ou fonctionner de façon autonome. Elle fonde ainsi sa proposition de valeur sur une approche flexible et innovante de la data science avec des travaux de recherche en IA, ML, Deep Learning, NLP ou encore étude des graphes."
        />
      </Helmet>

      <Accueil />
    </div>
  );
}

export default HomePage;
