import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
/* Components */
import Particles from "react-particles-js";
import Navigation from "./Navigation";
import Advisory from "./Pages/Advisory";
import HomePage from "./Pages/Homepage";
import Factory from "./Pages/Factory";
import Contact from "./Pages/Contact";
import MentionsLegales from "./Pages/MentionsLegales";
import Confidentialite from "./Pages/Confidentialite";
import { useMatomo } from '@datapunt/matomo-tracker-react'
// import BannerInfo from "./BannerInfo";

function App() {
  const { trackPageView } = useMatomo()

  React.useEffect(() => {
    trackPageView()
  }, [])

  return (
    <Router>
 <Particles id="particles-js" 
        params={{
          particles: {
            number: {
              value: 52,
              onresize: {
                value:10,
              },
            },
            
            density: {
              enable: true,
              value_area: 200,
            },
            color: {
              value: "#fff",
            },
            shape: {
              type: "circle",
            },
            stroke: {
              width: 0,
              color: "#fff",
            },
            polygon: {
              nb_sides: 5,
            },
            opacity: {
              value: 0.3630136445780753,
              random: false,
              anim: {
                enable: false,
                speed: 0.23974491141425514,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#fff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 5,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "bounce",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },

          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 2,
          },
          remove: {
            particles_nb: 2,
          },
          retina_detect: true,
        }}
      />
      {/* <BannerInfo/> */}
      <Navigation />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/advisory">
          <Advisory />
        </Route>
        <Route exact path="/factory">
          <Factory />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/mentionslegales">
          <MentionsLegales />
        </Route>
        <Route exact path="/confidentialite">
          <Confidentialite />
        </Route>
      
      </Switch>
    </Router>
  );
}
export default App;
