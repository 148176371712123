import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

import "../../styles/accueil.css";
import "../../styles/footer.css";

import Footer from "../Footer";

function MentionsLegales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = "Mentions légales";
  const maj = "Dernière mise à jour : 03/10/2023";

  return (
    <div>
      <Helmet>
        <title>Sahar | Analyse de données massives et solutions cyber</title>
        <meta
          name="description"
          content="SAHAR est spécialisée dans la collecte, l’analyse et la visualisation de données massives. La société conçoit des outils pouvant s’insérer dans des chaînes de traitement ou fonctionner de façon autonome. Elle fonde ainsi sa proposition de valeur sur une approche flexible et innovante de la data science avec des travaux de recherche en IA, ML, Deep Learning, NLP ou encore étude des graphes."
        />
      </Helmet>

      <Container className="container">
        <Row>
          <Col
            lg={12}
            style={{ textAlign: "center", marginBottom: "8%", padding: "0" }}
          >
            <h2 style={{ textAlign: "center", margin: "0", padding: "0" }}>
              {title}
            </h2>
            <span>{maj}</span>
          </Col>
        </Row>

        <Row>
          <Col lg={12} style={{ marginBottom: "8%" }}>
            <p>
              Conformément aux dispositions de la loi n° 2004-575 du 21 juin
              2004 pour la confiance en l'économie numérique, il est précisé aux
              utilisateurs du site{" "}
              <a style={{ color: "#4F71EC" }} href="https://sahar.fr">
                Sahar
              </a>{" "}
              l'identité des différents intervenants dans le cadre de sa
              réalisation et de son suivi.{" "}
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={12} style={{ marginBottom: "8%" }}>
            <h4>Edition du site </h4>
            <p>
              Le présent site, accessible à l’URL{" "}
              <a style={{ color: "#4F71EC" }} href="https://sahar.fr">
                <i>https://sahar.fr</i>
              </a>{" "}
              (le « Site »), est édité par :
            </p>
            <p>
              <i>
                <b>SAHAR, SAS </b>
              </i>
              société au capital de <i>200 000</i> euros, inscrite au R.C.S. de{" "}
              <i>Paris</i> sous le numéro <i>852 384 874</i>, dont le siège
              social est situé au{" "}
              <i>10 rue Fromentin, 75009 Paris, France</i>,
              représentée par <i>FRANZ Antoine</i> dûment habilité,{" "}
            </p>
            <p>
              Le numéro individuel TVA de l’Exploitant est <i>FR88852384874</i>.
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={12} style={{ marginBottom: "8%" }}>
            <h4>Hébergement </h4>
            <p>
              Le Site est hébergé par la société <i>Scaleway</i>, située{" "}
              <i>BP 438 75366 Paris CEDEX 08 France</i>, (téléphone :{" "}
              <i>+33 (0)1 84 13 00 00)</i>.{" "}
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={12} style={{ marginBottom: "8%" }}>
            <h4>Directeur de publication </h4>
            <p>
              Le Directeur de la publication du Site est <i>FRANZ Antoine</i>.
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={12} style={{ marginBottom: "8%" }}>
            <h4>Nous contacter</h4>
            <p>
              Par email : <i>contact@sahar.fr</i>
            </p>
            <p>
              Par courrier : <i>10 rue Fromentin, 75009</i>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={12} style={{ marginBottom: "8%" }}>
            <h4>Données personnelles </h4>
            <p>
              Le traitement de vos données à caractère personnel est régi par
              notre{" "}
              <Link
                style={{ color: "#4F71EC" }}
                to="/confidentialite"
              >

                Charte du respect de la vie privée
              </Link>{" "}
              conformément au Règlement Général sur la Protection des Données
              2016/679 du 27 avril 2016 (« RGPD »).{" "}
            </p>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default MentionsLegales;
