import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
/* Components */
import FactoryExpertise from "../../components/Factory/FactoryExpertise";

function Factory() {
  useEffect(() => {
    window.scrollTo(0,0)
}, []);
  return (
    <div>
      <Helmet>
        <title>Sahar | Factory</title>
        <meta
          name="description"
          content="SAHAR développe des outils cyber qui visent à collecter, retraiter, enrichir et visualiser de la donnée issue du web ouvert."
        />
      </Helmet>

      <FactoryExpertise />
    </div>
  );
}

export default Factory;
