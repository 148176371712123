import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
/*
import "../../styles/index.css";*/
import "../../styles/accueil.css";
import "../../styles/footer.css";
import arrowRight from "../../assets/arrow-right.svg";

import Footer from '../Footer';

function Accueil() {
  const heroTitle = "Analyse de données massives & solutions cyber";
  const heroDescription =
    "SAHAR est spécialisée dans la collecte, l’analyse et la visualisation de données massives. La société conçoit des outils pouvant s’insérer dans des chaînes de traitement ou fonctionner de façon autonome. Elle fonde ainsi sa proposition de valeur sur une approche flexible et innovante de la data science avec des travaux de recherche en IA, ML, Deep Learning, NLP ou encore étude des graphes.";

  const expertiseTitle = "Nos expertises"
  const advisoryLegend = "01. CONSEILS & STRATÉGIE";
  const advisoryTitle = "Advisory";
  const advisoryDescription =
    "Nous accompagnons les acteurs privés et publics dans la conduite de leur projet data, et dans les développements stratégiques à haute complexité.";

  const factoryLegend = "02. SOLUTIONS CYBER";
  const factoryTitle = "Factory";
  const factoryDescription =
    "Nous développons des outils cyber qui visent à collecter, retraiter, enrichir et visualiser de la donnée issue du web ouvert.";

  const linkMore = "En savoir plus";
  const linkExpertise = "Nos expertises"
  const contactLink = "Nous contacter"

  const aboutTitle = "À propos"
  const aboutDescription = "SAHAR a vocation de contribuer à la maîtrise technologique française en matière de solutions cyber et de data science. Notre mission est d'être un partenaire de confiance dans les domaines du Big Data, du Deep Learning et de l’IA. Nous prenons le temps de comprendre les besoins de nos clients et ainsi leur fournir les meilleures services et solutions techniques avec une approche strictement confidentielle."
  const aboutSubDescription = "Ces valeurs confèrent à SAHAR un ADN : celui d’une entreprise engagée avec une grande exigence de responsabilité sociétale et d’une attention portée sur la défense de la souveraineté technologique."
  
  return (
    <div>
      <Container className="container">
        {/* HERO */}
        <Row>
          <Col lg={12}>
            <div className="hero">
              <div className="title">
                  <h1>{heroTitle}</h1>
              </div>
              <div className="description">
                <p>{heroDescription}</p>
              </div>
              <div className="button">
            <a href="#expertise">
                <img className="arrow" src={arrowRight} alt="" />
                {linkExpertise}
              </a>
            </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* ACTIVITIE */}
      <Container className="container" id="expertise">
        <Row>
            <Col lg={12}>
                <div className="title">
                    <h2>{expertiseTitle}</h2>
                </div>
            </Col>
        </Row>
        <Row>
          <Col lg={6}>
              <div className="expertise advisory">
            <div className="expertise-legend"><span>{advisoryLegend}</span></div>
            <div className="expertise-title"><h3>{advisoryTitle}</h3></div>
            <div className="expertise-description">
              <p className="textes-mineurs">{advisoryDescription}</p>
            </div>
            <div className="button">
            <Link to="/advisory">
                <img className="arrow" src={arrowRight} alt="" />
                {linkMore}
              </Link>
            </div>
            </div>
          </Col>

          <Col lg={6}>
          <div className="expertise factory">
            <div className="expertise-legend"><span>{factoryLegend}</span></div>
            <div className="expertise-title"><h3>{factoryTitle}</h3></div>
            <div><p className="textes-mineurs">{factoryDescription}</p></div>
            <div className="button">
            <Link to="/factory">
                <img className="arrow" src={arrowRight} alt="" />
                {linkMore}
              </Link>
            </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* A PROPOS */}
      <Container className="container" id="about">
      <Row> 
            <Col lg={12}>
                <div className="title">
                    <h2>{aboutTitle}</h2>
                </div>
            </Col>
        </Row>
        <Row>
        <Col lg="12" >
        <div className="description" style={{marginBottom:'3%'}}>
                <p style={{paddingBottom:'2%'}}>{aboutDescription}</p>
                <p>{aboutSubDescription}</p>
              </div>
              <div className="button" style={{padding:'0'}}>
            <Link to="/contact">
                <img className="arrow" src={arrowRight} alt="" />
                {contactLink}
              </Link>
            </div>
              </Col>
        </Row>
      </Container>
      <Footer/>
    </div>
  );
}

export default Accueil;
