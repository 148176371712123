import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
/* Style */
import "../App.css";
import "../styles/Navigation.css";
/* Médias */
import logo from "../assets/Logo-White.svg"

class Navigation extends React.Component {


  constructor(props) {
    
    super(props);
    this.state = {
      menuOpen: false,
    };
    
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
    console.log(state);
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  

  render() {
    // NOTE : https://github.com/negomi/react-burger-menu#styling
    return (
      <div>
        <div className="navigation">
          <Link to="/">
            {" "}
            <img className="logo" src={logo} alt="" />
          </Link>
        </div>

        <Menu
          right
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
         
        >
          <Link
            id="home"
            className="menu-item"
            to="/"
            onClick={() => this.closeMenu()}
          >
            Accueil
          </Link>
          <Link
            id="about"
            className="menu-item"
            to="/advisory"
            onClick={() => this.closeMenu()}
          >
            Advisory
          </Link>
          <Link
            id="contact"
            className="menu-item"
            to="/factory"
            onClick={() => this.closeMenu()}
          >
            Factory
          </Link>
          <Link
            id="contact"
            className="menu-item"
            to="/contact"
            onClick={() => this.closeMenu()}
          >
            Contact
          </Link>
          <div className="info">
            <div className="info-bloc">
              <span>Siège Paris</span>
              <div className="info-detail">10 rue Fromentin</div>
              <div className="info-detail">75009 PARIS</div>
            </div>
           
            <div className="info-bloc">
              <span> Contact</span>
              <div className="info-detail">contact@sahar.fr</div>
            
            </div>


            {/* 
            <div className="info-bloc">

              <div className="politique-confidentialite">
                <Link
                  to="/politique-confidentialite"
                  id="contact"
                  onClick={() => this.closeMenu()}
                  >
                  
                POLITIQUE DE CONFIDENTIALITÉ
                </Link>
              </div>
             
            
            </div>

            */}
          </div>
        </Menu>
      </div>
    );
  }
}
export default Navigation;